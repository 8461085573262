<template>
  <div>
    <div class="container-xl">
      <nav class="navbar navbar-expand-xxl navbar-dark justify-content-right">
        <!-- Brand -->

        <!-- Toggler/collapsibe Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/Company"
                style="color: rgba(24, 163, 110, 1)"
                >Company</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/platform">Platform</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/RegisterNow"
                style="
                  border-radius: 343px;
                  background: rgba(24, 163, 110, 1);
                  color: #fff;
                "
                >Register Now</a
              >
            </li>
            <li>
              <img
                style="margin-left: 20px"
                src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_menu.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div>
      <img
        style="width: 100%; position: relative"
        src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_17.png?x-oss-process=style/style"
        alt=""
      />
      <div class="header container-xl">
        <div class="header_div">
          <h2 class="header_h2">What Is</h2>
          <h2 class="header_h2" style="color: rgba(24, 163, 110, 1)">
            OrthoDesign
          </h2>
        </div>
      </div>
    </div>
    <div class="Introduce">
      <div class="container-xl" style="width: 60%">
        <h3 class="Introduce_h3">Introduce</h3>
        <p class="header_p">
          OrthoDesign is a globally recognized leader
          <span style="color: rgba(24, 163, 110, 1)"
            >in high-tech orthodontics,</span
          ><br />
          with over
          <span style="color: rgba(24, 163, 110, 1); font-size: 26px"
            >25 years</span
          >
          of experience. <br /><br />
          Our cutting-edge aligner solutions, backed by an impressive success
          rate of over<br />
          <span style="color: rgba(24, 163, 110, 1)"
            >10,000 cases worldwide,</span
          >
          showcase our expertise and innovation.<br />
          By integrating the latest technologies into our fully digital design
          and manufacturing process, we deliver precise and customized aligners
          with exceptional results.<br /><br />
          With a strong presence in over
          <span style="color: rgba(24, 163, 110, 1); font-size: 26px"
            >45 countries,</span
          >
          trust OrthoDesign for transformative orthodontic care that combines
          extensive experience, cutting-edge innovation, and a proven track
          record of credibility.
        </p>
      </div>
    </div>
    <div class="products">
      <h2 class="products_h2">
        <img
          class="products_img"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
          alt=""
        />
        <span style="color: rgba(24, 163, 110, 1)">Q&A</span> Section
        <img
          class="products_img"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
          alt=""
        />
      </h2>
      <div class="products_div">
        <img
          style="width: 70%"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_18.png"
          alt=""
        />
        <div class="Product_right">
          <h3>Invisible aligners</h3>
          <div class="line"></div>
          <p>
            Invisible aligners are discreet orthodontic appliances that
            gradually straighten teeth without the need for traditional braces.
            At OrthoDesign, we assist doctors in creating ideal aligners through
            our advanced technology and expertise, ensuring precise and
            customized treatments for optimal results.
          </p>
        </div>
      </div>
      <div class="products_div_2">
        <img
          style="width: 70%"
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_19.png"
          alt=""
        />
        <div class="Product_left">
          <h3>Snoring Device</h3>
          <div class="line" style="margin: 20px 0 20px auto"></div>
          <p>
            Sleep apnea devices are specialized appliances that help treat sleep
            apnea and snoring. At OrthoDesign, we assist doctors in creating
            ideal sleep apnea devices through our advanced technology and
            expertise, providing precise and customized solutions for effective
            treatment and improved sleep quality.
          </p>
        </div>
      </div>
    </div>
    <div style="background-color: #e5faf2">
      <div class="bottom_1 container-xl">
        <h2 class="bottom_h2">
          <img
            class="products_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
          Well received
          <img
            class="products_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
            alt=""
          />
        </h2>
        <div class="Carousel">
          <el-carousel
            :autoplay="false"
            ref="carousel1"
            arrow="always"
            indicator-position="outside"
          >
            <el-carousel-item v-for="item in received" :key="item">
              <h3 class="Carousel_h3">{{ item.title }}</h3>
              <button class="Carousel_button">{{ item.button }}</button>
              <p class="Carousel_p">{{ item.content }}</p>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="footer">
      <h3 class="footer_h3">
        Orthodesign : Streamline Your Orthodontic Experience
      </h3>
      <h5 class="footer_h5">
        Easy to use/Affordable Pricing/Quality Guarantee/Instant shipping
      </h5>
    </div>
    <div class="bottom">
      <div class="bottom_div container-xl">
        <div>
          <img
            style="width: 135px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_23.png"
            alt=""
          />
        </div>
        <div style="text-align: left">
          <p style="margin-bottom: 10px">Home</p>
          <p style="margin-bottom: 10px">Company</p>
          <p style="margin-bottom: 10px">Platform</p>
          <p style="margin-bottom: 10px">Pricing</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_24.png"
            alt=""
          />
          <p style="font-size: 20px; margin-top: 10px">+852 5388 7318</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_25.png"
            alt=""
          />
          <p style="font-size: 18px; margin-top: 10px">
            On the third floor of Luohu District, <br />
            Shenzhen City, Guangdong Province
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
        <script>
export default {
  components: {},
  data() {
    return {
      received: [
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
        {
          title: "AlignSmile",
          button: "Seamless Experience",
          content:
            "I recently used AlignSmile for my orthodontic treatment, and I couldn't be happier with the experience. The combination of AI machine learning and professional doctors ensures a seamless process from start to finish. The aligners were precisely tailored to my dental structure, and the treatment progress was closely monitored. AlignSmile made my journey to a straighter smile incredibly convenient and efficient.",
        },
      ],
    };
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>
        <style scoped>
a:hover {
  color: #00e567 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-collapse {
  flex-direction: row-reverse;
}
.navbar {
  margin-right: 30px;
}
.nav-item {
  padding: 0 15px;
}
.header {
  width: 100%;
  /* height: 460px; */
  opacity: 1;
  background: rgba(204, 204, 204, 0);
  /* background-image: url(https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_17.png?x-oss-process=style/style); */
  /* background-size: 100% 100%; */
  text-align: center;
}

.header_div {
  width: 750px;
  text-align: left;
  position: absolute;
  top: 30%;
  left: 25%;
}
.header_h2 {
  font-size: 98px;
  font-weight: 600;
  margin-bottom: 10px;
}
.header_p {
  font-size: 22px;
}
.line {
  width: 100px;
  height: 5px;
  margin: 20px 0;
  background-color: rgba(24, 163, 110, 0.9);
}
.products {
  text-align: center;
  padding: 50px 0;
  margin: 50px 0;
}
.products_h2 {
  font-size: 38px;
}
.products_img {
  margin: 0 20px;
}
.products_div {
  text-align: right;
  margin: 60px 0 20px 0;
  position: relative;
}
.products_div_2 {
  text-align: left;
  margin: 60px 0 60px 0;
  position: relative;
}
.Product_right {
  width: 540px;
  text-align: left;
  position: absolute;
  top: 88px;
  right: 15%;
}
.Product_left {
  width: 540px;
  text-align: right;
  position: absolute;
  top: 88px;
  left: 15%;
}
.Introduce {
  margin-top: 50px;
  background: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_9.png)
    no-repeat bottom right;
}
.Introduce_h3 {
  width: 360px;
  text-align: center;
  font-size: 46px;
  margin: 50px auto;
  background: linear-gradient(
    90deg,
    rgba(24, 163, 110, 0) 0%,
    rgba(24, 163, 110, 0.35) 51.39%,
    rgba(24, 163, 110, 0) 100%
  );
}
.bottom_1 {
  width: 100%;
  height: 540px;
  padding: 1px 0;
  text-align: center;
  background-image: url(https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_20.png?x-oss-process=style/style);
  background-size: 100% 100%;
}
.bottom_h2 {
  margin: 30px 0;
}
.bottom {
  width: 100%;
  padding: 40px 0;
  margin-top: 60px;
  text-align: center;
  color: #fff;
  background: rgba(54, 176, 130, 1);
}
.bottom_div {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  background: rgba(54, 176, 130, 1);
}
.Carousel {
  margin: auto;
  width: 950px;
  height: 300px;
  margin-top: 62px;
}
.Carousel_h3 {
  color: #fff;
  margin-top: 20px;
}
.Carousel_button {
  width: 299px;
  height: 30px;
  border: none;
  margin: 18px 0;
  border-radius: 238px;
  background: rgba(255, 255, 255, 1);
}
.Carousel_p {
  color: #fff;
  width: 80%;
  margin: auto;
}
.el-carousel >>> .el-carousel__arrow {
  background-color: transparent;
}
.el-carousel >>> .el-carousel__arrow i {
  color: #fff;
  font-size: 42px;
  font-weight: 900;
}
.el-carousel >>> .el-carousel__arrow--left {
  left: -9px;
}
.el-carousel >>> .el-carousel__arrow--right {
  right: -6px;
}

.el-carousel >>> .el-carousel__indicator--horizontal button {
  width: 10px;
  height: 10px;
  background: rgba(50, 62, 72, 0.3);
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel >>> .el-carousel__indicator--horizontal.is-active button {
  width: 10px;
  height: 10px;
  background: rgba(24, 163, 110, 1);
  border-radius: 50%;
  opacity: 0.5;
}
.footer {
  text-align: center;
  padding-top: 100px;
}
.footer_h3 {
  font-size: 28px;
  font-weight: 700;
  color: rgba(24, 163, 110, 1);
}
.footer_h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 100px 0;
}
.van-collapse-item >>> .van-cell__title {
  color: rgba(24, 163, 110, 1);
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "Omnium-Bold";
}
p,
span,
div {
  font-family: "Comfortaa-Bold";
}
</style>