<template>
  <div>
    <div class="container-xl">
      <nav class="navbar navbar-expand-xxl navbar-dark justify-content-right">
        <!-- Brand -->

        <!-- Toggler/collapsibe Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Company">Company</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/platform"
                style="color: rgba(24, 163, 110, 1)"
                >Platform</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/beame/Pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="/beame/RegisterNow"
                style="
                  border-radius: 343px;
                  background: rgba(24, 163, 110, 1);
                  color: #fff;
                "
                >Register Now</a
              >
            </li>
            <li>
              <img
                style="margin-left: 20px"
                src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_menu.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div>
      <img
        style="width: 100%; position: relative"
        src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_21.png?x-oss-process=style/style"
        alt=""
      />
      <div class="header">
        <div class="header_div">
          <h2 class="header_h2" style="color: rgba(50, 62, 72, 1)">
            OrthoDesign
          </h2>
          <h3 class="header_h2" style="font-size: 34px">
            Digital treatment management platform
          </h3>
          <button class="header_button">Sign up to OrthoDesign</button>
        </div>
      </div>
    </div>
    <div class="Introduce">
      <img
        src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_15.png"
        alt=""
      />
      <div class="container-xl" style="width: 60%">
        <p class="header_p">
          We are a professional
          <span style="color: rgba(24, 163, 110, 1)"
            >aligner manufacturing</span
          >
          and
          <span style="color: rgba(24, 163, 110, 1)">design company </span>
          <br />offering an intuitive
          <span style="color: rgba(24, 163, 110, 1)">online platform</span
          ><br />
          that provides dentists with exhaustive control over their patients'
          treatments.<br /><br />
          <span style="color: rgba(24, 163, 110, 1)">Our platform</span> is
          compatible with all intraoral scanners, allowing for seamless
          integration and optimization of workflow, reducing time in each step
          of the process. Dentists can request personalized aligner treatments
          with specified delivery and collection conditions, ensuring a
          user-friendly experience.<br /><br />
          <span style="color: rgba(24, 163, 110, 1)"
            >Experience the efficiency of our digital tool, designed to enhance
            the aligner manufacturing and design process while delivering
            exceptional orthodontic care.</span
          >
        </p>
      </div>
    </div>
    <div class="beVexed" style="">
      <h3 class="beVexed_title">
        Democratizing Affordable Invisible Alignment Treatment:
      </h3>
      <h3 class="beVexed_title_2">
        Empowering Clinics with Accessible and Affordable AI Solutions.
      </h3>
      <div class="beVexed_step">
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_3.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Sign up to OrthoDesign WebsiteCreate patient file
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_4.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Scan your patient with a3DscannerUpload your treatmetn
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_5.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Receive your Al machining treatment PlanConfirm your treatment
          </p>
        </div>
        <div class="beVexed_step_div">
          <img
            class="beVexed_step_img"
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/America_6.png"
            alt=""
          />
          <p class="beVexed_step_p">
            Pay for the aligner Box and AlignersStripe/Credit Card
          </p>
        </div>
      </div>
      <button class="beVexed_button">Recieve the Patient Box</button><br />
      <p>Moniter the patient through patient app</p>
    </div>
    <div class="content">
      <div class="content_left">
        <div style="display: flex; justify-content: center">
          <div
            style="
              margin: 0 30px 20px auto;
              width: 10px;
              height: 80px;
              background-color: rgba(24, 163, 110, 1);
            "
          ></div>
          <h2>
            Compatibility with <br />
            all 3D <br />scanners!
          </h2>
        </div>
      </div>
      <div class="content_right">
        <p>
          Embrace the future of orthodontics with OrthoDesign, a cutting-edge AI
          design and manufacturing company. Our systems are fully compatible
          with all major intraoral scanner brands, ensuring a seamless
          transition into the digital workflow. Step into a new era of
          efficiency and precision as our dedicated digital support team guides
          you through the process of sending your first cases using an intraoral
          scanner. Experience the power of our digital solutions,
          revolutionizing the way you design and manufacture aligners.
        </p>
      </div>
    </div>
    <div class="footer">
      <h3 class="footer_h3">
        Orthodesign : Streamline Your Orthodontic Experience
      </h3>
      <h5 class="footer_h5">
        Easy to use/Affordable Pricing/Quality Guarantee/Instant shipping
      </h5>
    </div>
    <div class="bottom">
      <div class="bottom_div container-xl">
        <div>
          <img
            style="width: 135px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_23.png"
            alt=""
          />
        </div>
        <div style="text-align: left">
          <p style="margin-bottom: 10px">Home</p>
          <p style="margin-bottom: 10px">Company</p>
          <p style="margin-bottom: 10px">Platform</p>
          <p style="margin-bottom: 10px">Pricing</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_24.png"
            alt=""
          />
          <p style="font-size: 20px; margin-top: 10px">+852 5388 7318</p>
        </div>
        <div style="text-align: left">
          <img
            style="width: 60px"
            src="https://beame.oss-accelerate.aliyuncs.com/mobilePicture/America_25.png"
            alt=""
          />
          <p style="font-size: 18px; margin-top: 10px">
            On the third floor of Luohu District, <br />
            Shenzhen City, Guangdong Province
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
          <script>
export default {
  components: {},
  data() {
    return {};
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>
          <style scoped>
a:hover {
  color: #00e567 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-collapse {
  flex-direction: row-reverse;
}
.navbar {
  margin-right: 30px;
}
.nav-item {
  padding: 0 15px;
}
.header {
  background: rgba(204, 204, 204, 0);
  text-align: center;
  position: absolute;
  top: 260px;
  left: 15%;
}

.header_div {
  /* width: 100%; */
  text-align: center;
}
.header_button {
  width: 400px;
  height: 56px;
  font-size: 28px;
  border: none;
  color: #fff;
  border-radius: 761px;
  background: rgba(24, 163, 110, 1);
}
.header_h2 {
  font-size: 78px;
  font-weight: 600;
  margin-bottom: 40px;
  color: rgba(24, 163, 110, 1);
}
.header_p {
  text-align: left;

  font-size: 20px;
  margin: 70px 0 0 0px;
}
.beVexed {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 90px 0;
  background: rgba(70, 82, 98, 0.1);
}
.beVexed_title {
  color: rgba(24, 163, 110, 1);
}

.beVexed_title_2 {
  margin-bottom: 30px;
}
.beVexed_step {
  text-align: center;
  display: flex;
  justify-content: center;
}
.beVexed_step_div {
  width: 300px;
  border-radius: 32.64px;
  margin: 0 10px 40px 10px;
  padding: 50px 0;
}

.beVexed_step_img {
  width: 100%;
}
.beVexed_step_p {
  margin: auto;
  margin-top: -121px;
  width: 200px;
}
.beVexed_button {
  width: 384px;
  height: 48px;
  border: none;
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 560px;
  background: rgba(24, 163, 110, 1);
}

.Introduce {
  margin-top: 50px;
}
.content {
  width: 90%;
  height: 320px;
  margin: auto;
  border-radius: 29px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background: rgba(50, 62, 72, 0.1);
  align-items: center;
}
.content_left {
  width: 400px;
}
.content_right {
  width: 500px;
}
.footer {
  text-align: center;
  padding-top: 100px;
}
.footer_h3 {
  font-size: 28px;
  font-weight: 700;
  color: rgba(24, 163, 110, 1);
}
.footer_h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 100px 0;
}
.bottom {
  width: 100%;
  padding: 40px 0;
  margin-top: 60px;
  text-align: center;
  color: #fff;
  background: rgba(54, 176, 130, 1);
}
.bottom_div {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  background: rgba(54, 176, 130, 1);
}
.van-collapse-item >>> .van-cell__title {
  color: rgba(24, 163, 110, 1);
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "Omnium-Bold";
}
p,
span,
div {
  font-family: "Comfortaa-Bold";
}
</style>